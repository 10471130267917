
import { RoleTypes, SystemRole } from "@/store/modules/role/role.types";
import { Pagination } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { namespace } from "vuex-class";
import { commaDate, currency, pad, slash, dash, uuid } from "@/filters/utils";

const roleX = namespace("Role");

@Component({
  components: {
    IDatePicker: () => import("@/components/utils/IDatePicker.vue"),
    AddRole: () => import("@/components/role/AddRole.vue"),
    EditRole: () => import("@/components/role/EditRole.vue"),
    DeleteRole: () => import("@/components/role/DeleteRole.vue"),
  },
  filters: {
    currency,
    slash,
    commaDate,
    pad,
    dash,
    uuid,
  },
})
export default class SystemRolesView extends Vue {
  @roleX.Mutation(RoleTypes.SET_ADD_ROLE_DIALOG)
  public setAddRole!: (addRole: boolean) => void;

  @roleX.Mutation(RoleTypes.SET_UPDATE_ROLE_DIALOG)
  public setUpdateRole!: (updateRole: boolean) => void;

  @roleX.Mutation(RoleTypes.SET_DELETE_ROLE_DIALOG)
  public setDeleteRole!: (deleteRole: boolean) => void;

  @roleX.Mutation(RoleTypes.SET_TICKET_ROLE_DATA)
  public setSystemRoleData!: (ticketRole: SystemRole) => void;

  @roleX.Action(RoleTypes.LOAD_ROLES)
  public getSystemRoles!: (pagination: Pagination) => void;

  @roleX.State(RoleTypes.ROLES)
  public ticketRoles!: SystemRole[];

  @roleX.State(RoleTypes.LOADING_ROLES_STATE)
  public loadingRoles!: boolean;

  @roleX.State("addRoleRef")
  public addRoleRef!: number;

  openAddRoleDialog(): void {
    this.setAddRole(true);
  }

  public search = "";

  public headers: DataTableHeader[] = [
    {
      text: "#ID",
      sortable: false,
      value: "id",
      cellClass: "primary--text text--darken-2 font-weight-medium",
    },
    { text: "NAME", value: "name", cellClass: "grey--text text--darken-2" },
    {
      text: "DESCRIPTION",
      value: "description",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "STATUS",
      value: "active",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "CREATED",
      value: "createdAt",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "ACTIONS",
      value: "actions",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
  ];

  deleteRole(item: SystemRole): void {
    this.setDeleteRole(true);
    this.setSystemRoleData(item);
  }

  editRole(item: SystemRole): void {
    this.setUpdateRole(true);
    this.setSystemRoleData(item);
  }

  mounted(): void {
    this.getSystemRoles({ page: 1, limit: 10 });
  }
}
